import React from "react";
import Button, { ButtonKind } from "../components/Button";
import Container from "../components/Container";
import {
  circleLarge,
  CTAIllustration,
  loader,
  target,
  waveHorizontalLarge,
  waveVerticalSmall
} from "../components/Images";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

interface CTAProps {
  buttonLink: string;
  buttonText: string;
  claim: string;
}

const CTAUsContent = styled.div`
  position: relative;
  padding-top: 167px;
  padding-bottom: 120px;
  background-color: ${theme.colors.blueLight};
  text-align: center;
  background-size: 160px 17px, 28px 28px, 28px 28px, 21px 21px, 17px 47px;
  background-image: url(${waveHorizontalLarge}), url(${circleLarge}),
    url(${target}), url(${loader}), url(${waveVerticalSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 240px) top
      115px,
    left calc(${theme.container.background} + 70px) top 70%,
    right calc(${theme.container.background} - 50px) bottom 20%,
    right calc(${theme.container.background} - 65px) top 42%,
    right calc(${theme.container.background} - 115px) top 28%;
  ${[theme.media.maxL]} {
    padding-bottom: 60px;
  }

  h2 {
    margin-bottom: 1em;
    font-size: 50px;
    ${[theme.media.maxM]} {
      font-size: 30px;
    }
  }

  h2,
  a {
    position: relative;
  }
`;

const CTAUsIllustration = styled.img`
  position: absolute;
  top: -90px;
  left: 50%;
  pointer-events: none;
`;

class CTAUs extends React.Component<CTAProps> {
  render() {
    const { buttonLink, buttonText, claim } = this.props;
    return (
      <CTAUsContent>
        <Container>
          <CTAUsIllustration src={CTAIllustration} />
          <h2>{claim}</h2>
          <Button link={buttonLink} kind={ButtonKind.Large}>
            {buttonText}
          </Button>
        </Container>
      </CTAUsContent>
    );
  }
}

export default CTAUs;
