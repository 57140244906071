import { FormattedHTMLMessage, FormattedMessage } from "gatsby-plugin-intl";
import React from "react";
import Container from "../../components/Container";
import {
  aboutUsIllustration,
  circleLarge,
  target,
  waveHorizontalLarge
} from "../../components/Images";
import Row from "../../components/Row";
import styled from "../../styling/styled";
import { theme } from "../../styling/theme";

const JumbotronContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 80px;
  background-size: 28px 28px, 160px 17px, 28px 28px, 160px 17px;
  background-image: url(${target}), url(${waveHorizontalLarge}),
    url(${circleLarge}), url(${waveHorizontalLarge});
  background-repeat: no-repeat;
  background-position: right calc(${theme.container.background} - 100px) top
      30px,
    left calc(${theme.container.background} - 277px) top 15%,
    left calc(${theme.container.background} - 150px) bottom 25%,
    right calc(${theme.container.background} - 240px) bottom 15%;

  ${[theme.media.mobileMenuVisible]} {
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: 0;
  }

  h3 {
    margin-top: 50px;
  }
`;

const Illustration = styled.div`
  img {
    display: block;
    margin-left: auto;
    width: calc(100% + 100px);
    margin-top: -50px;
    margin-right: -50px;
    ${[theme.media.maxM]} {
      position: absolute;
      top: -40px;
      margin-top: 0;
      right: -40px;
      width: 50vw;
      z-index: -1;
    }
    ${[theme.media.maxS]} {
      right: 0px;
    }
  }
`;

const RowJumbotron = styled(Row)`
  align-items: center;
`;

const ColText = styled.div`
  max-width: 40%;
  flex-basis: 40%;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  ${[theme.media.maxM]} {
    max-width: 75%;
    flex-basis: 75%;
  }
  ${[theme.media.maxS]} {
    max-width: 90%;
    flex-basis: 90%;
  }
`;

const ColImage = styled.div`
  max-width: 60%;
  flex-basis: 60%;
  padding-left: 15px;
  padding-right: 15px;
  ${[theme.media.maxM]} {
    max-width: 100%;
    flex-basis: 100%;
    order: -1;
  }
`;

class Jumbotron extends React.Component {
  render() {
    return (
      <JumbotronContent>
        <Container>
          <RowJumbotron spacing={25}>
            <ColText>
              <h1>
                <FormattedMessage id="partials.aboutus.jumbotron.title1" />
              </h1>
              <p>
                <FormattedHTMLMessage id="partials.aboutus.jumbotron.text1" />
              </p>
              <h3>
                <FormattedMessage id="partials.aboutus.jumbotron.title2" />
              </h3>
              <p>
                <FormattedHTMLMessage id="partials.aboutus.jumbotron.text2" />
              </p>
              <h3>
                <FormattedMessage id="partials.aboutus.jumbotron.title3" />
              </h3>
              <p>
                <FormattedHTMLMessage id="partials.aboutus.jumbotron.text3" />
              </p>
              <h3>
                <FormattedMessage id="partials.aboutus.jumbotron.title4" />
              </h3>
              <p>
                <FormattedHTMLMessage id="partials.aboutus.jumbotron.text4" />
              </p>
            </ColText>
            <ColImage>
              <Illustration>
                <img src={aboutUsIllustration} alt="About us illustration" />
              </Illustration>
            </ColImage>
          </RowJumbotron>
        </Container>
      </JumbotronContent>
    );
  }
}

export default Jumbotron;
