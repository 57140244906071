import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import FooterBackground from "../components/FooterBackground";
import Layout from "../components/Layout";
import Jumbotron from "../partials/about_us/Jumbotron";
import CTA from "../partials/CTA";
import styled from "../styling/styled";

const Content = styled.div`
  padding-top: 0px;
  padding-bottom: 60px;

  h2 {
    text-align: center;
  }
`;

const VideoEmbed = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  &::before {
    display: block;
    content: "";
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

class AboutUsPage extends React.Component {
  render() {
    const intl = this.props.intl;

    let iframe;

    iframe = (
      <iframe
        width="560"
        height="315"
        src={intl.formatMessage({ id: "pages.aboutus.videourl" })}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        allowFullScreen
        rel="0"
      />
    );

    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.aboutus.title1" }) + " | Eledio.com"
          }
          meta={[
            {
              name: "description",
              content: intl.formatMessage({ id: "pages.aboutus.description" })
            },
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.aboutus.title1" })
            },
            {
              property: "og:description",
              content: intl.formatMessage({ id: "pages.aboutus.description" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <Jumbotron />
          <Content>
            <Container>
              <h2>
                <FormattedMessage id="pages.aboutus.title2" />
              </h2>
              <VideoEmbed>{iframe}</VideoEmbed>
            </Container>
          </Content>
          <CTA
            buttonLink="/jobs/"
            buttonText={intl.formatMessage({ id: "pages.jobs.title" })}
            claim={intl.formatMessage({ id: "pages.aboutus.ctaclaim" })}
          />
          <FooterBackground />
        </Layout>
      </>
    );
  }
}

export default injectIntl(AboutUsPage);
