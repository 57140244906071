import React from "react";
import styled from "../styling/styled";

interface Props {
  className?: string;
  children: React.ReactNode;
  spacing: number;
}

const RowContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${props => props.spacing}px;
  margin-right: -${props => props.spacing}px;
  & > div {
    padding-left: ${props => props.spacing}px;
    padding-right: ${props => props.spacing}px;
  }
`;

class Row extends React.Component<Props> {
  render() {
    const { children, spacing, className } = this.props;

    return (
      <RowContent spacing={spacing} className={className}>
        {children}
      </RowContent>
    );
  }
}

export default Row;
