import React from "react";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const FooterBackgroundContent = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 66px;
    z-index: -1;
    background-color: ${theme.colors.blueLight};
  }
`;

class FooterBackground extends React.Component {
  render() {
    return <FooterBackgroundContent />;
  }
}

export default FooterBackground;
